import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';

import Web3 from 'web3'
Vue.prototype.Web3 = Web3
// Vue.prototype.Clipboard = clipboard
// 引入echarts
// import echarts from 'echarts'

// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  next()

});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
